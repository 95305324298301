import React from 'react';
import { graphql } from 'gatsby';

import HelpLayout from '../../../components/HelpLayout';
import HelpArticle from '../../../components/HelpArticle';
import SEO from '../../../components/SEO';

const HowToAddVideoInWordpressElementorPage = ({ location, data }) => {
  return (
    <HelpLayout location={location}>
      <SEO
        title="How to add video in Wordpress Elementor"
        description="Learn how to add a video to your Wordpress Elementor page."
      />
      <HelpArticle
        title="How to add video in Wordpress Elementor"
        videoTitle="wordpress elementor guide"
        videoSrc={data.site.siteMetadata.wordpressElementorGuideVideoUrl}
      >
        <HelpArticle.StepContent>
          <p>
            In the <strong>Library</strong> select the video you want to embed
            in your webpage.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            Click on <strong>Actions</strong> and choose{' '}
            <strong>Get page embed code</strong>. Dialog will pop up and you
            press the <strong>Copy</strong> button. Now you have the embed code
            in your clipboard.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>Go to your Wordpress site’s editor.</p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            Search for the <strong>HTML</strong> element and drag it to the
            section where the video should be displayed.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            Paste the copied code into the <strong>HTML Code</strong> input and
            the video will appear.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>That’s it!</p>
        </HelpArticle.StepContent>
      </HelpArticle>
    </HelpLayout>
  );
};

export const query = graphql`
  query HowToAddVideoInWordpressElementorPageQuery {
    site {
      siteMetadata {
        wordpressElementorGuideVideoUrl
      }
    }
  }
`;

export default HowToAddVideoInWordpressElementorPage;
